import { useLocation } from '@remix-run/react';
import { useLayoutEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useUser } from '../components/UserContext';

type Option = {
  routePrefix: string;
  alignment?: 'left' | 'right';
};

export function useBootIntercom(routePrefixes: (string | Option)[]) {
  const user = useUser();
  const { boot, shutdown } = useIntercom();
  const location = useLocation();
  const matched = useMemo(
    () =>
      routePrefixes.find((r) => {
        if (typeof r === 'string') {
          return location.pathname.startsWith(r);
        }
        return location.pathname.startsWith(r.routePrefix);
      }),
    [location.pathname, routePrefixes]
  );
  const org = user.organizer?.organization;
  const orgSize = org?.maxSize;
  const subscriptionProduct = org?.subscription.productName;

  useLayoutEffect(() => {
    if (!matched || !user.id) return;
    boot({
      userId: user.id,
      name: user.username,
      email: user.email || '',
      customAttributes: {
        'Org Size': orgSize ?? 'N/A',
        'Subscription Product': subscriptionProduct ?? 'N/A',
      },
      alignment: typeof matched === 'string' ? 'right' : matched.alignment,
    });
    return () => shutdown();
  }, [
    boot,
    shutdown,
    matched,
    user.email,
    user.id,
    user.username,
    orgSize,
    subscriptionProduct,
  ]);
}
